const COMPONENT_NAMES = {
    // Ideally these go in the general order that the steps might appear
    POSTAL_CODE: 'PostalCode',
    SHOPPING_FOR: 'ShoppingFor',
    PRODUCT_INTEREST: 'ProductInterest',
    EV_CHARGER: 'EvCharger',
    PROPERTY_TYPE: 'PropertyType',
    BUSINESS_CONTACT: 'BusinessContact',
    OWNS_PROPERTY: 'OwnsProperty',
    OWNS_PROPERTY_COMMERCIAL: 'OwnsPropertyCommercial',
    TIME_TO_PURCHASE: 'TimeToPurchase',
    RENT_CRITERIA: 'RentCriteria',
    CSM_HANDOFF: 'CsmHandoff',
    SUSTAINABILITY: 'Sustainability',
    ELECTRICITY_BILL: 'ElectricityBill',
    EV_DETAILS: 'EvDetails',
    SERVICE_ERROR: 'ServiceError',
    STORAGE_INTEREST: 'StorageInterest',
    STORAGE_REASON: 'StorageReason',
    ADDRESS: 'Address',
    ROOF_PIN: 'RoofPin',
    ROOF_PIN_HELP: 'RoofPinHelp',
    ROOF_AGE: 'RoofAge',
    REMOVE_TREES: 'RemoveTrees',
    ORGANIZATION_NAME: 'OrganizationName',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    VERIFY_COMPLETE: 'VerifyComplete',
    SCHEDULE_CALL: 'ScheduleCall',
    HEAT_PUMPS_INSTALLER_SEARCH: 'HeatPumpsInstallerSearch',
    REQUEST_EMAIL: 'RequestEmail',
    SUMMARY: 'Summary',
    PANEL_DISTANCE: 'PanelDistance',
    GREAT_CHOICE: 'GreatChoice',
    UTILITY_PROVIDER: 'UtilityProvider',
    OWNED_APPLIANCES: 'OwnedAppliances',
    CONTACT_INFO: 'ContactInfo',
    PANEL_LOCATION: 'PanelLocation',
    ELECTRICAL_PANEL: 'ElectricalPanel',
    EV_THANKS: 'EvThanks',
    SQUARE_FOOTAGE: 'SquareFootage',
    HOME_TYPE: 'HomeType',
    HEAT_PUMP_EMERGENCY: 'HeatPumpEmergency',
    WHAT_TO_POWER: 'WhatToPower',
};

const CHOICE_VALUES = {
    HEATPUMP: 'heatpump',
    SOLAR_PV: 'solar-pv',
    EV_CHARGER: 'ev-charger',
    BATTERY_STORAGE: 'BatteryStorage',
    PROJECT_UNITY_ZIP: 'project_unity_zip',
    EV_CHARGER_PROJECT_UNITY: 'ev-charger-project-unity',
    SOLAR_PV_EV_CHARGER_PROJECT_UNITY: 'solar-pv-ev-charger-project_unity',
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONPROFIT: 'nonprofit',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    BACKUP: 'EBP',
    SAVINGS: 'UR',
    SELF_SUPPLY: 'SS',
    OLD_ROOF: 'more_than_20',
    NEW_ROOF: 'less_than_20',
    OLD_ROOF_REPLACE: 'more_than_20_replacing',
    YES_REMOVE: 'yes',
    NO_REMOVE: 'no',
    OWNER: 'owner',
    NON_OWNER: '',
    RENT_OTHER: 'shopping_someone_else',
    RENT_HOA: 'shopping_hoa_or_multicondo',
    RENT_SELF: 'rent_for_self',
    REPRESENT_OWNER: 'represent_owner',
    COMMERCIAL_OWNER: 'owner',
    COMMERCIAL_LEASE: 'commercial_lessee',
    WITHIN_MONTHS: 'next_few_months',
    WITHIN_YEAR: 'next_year',
    NOT_SURE: 'unsure',
    TRUE: 'true',
    FALSE: 'false',
    SCHEDULE_CALL: 'schedule_call',
    REQUEST_EMAIL: 'request_email',
    PANEL_DISTANCE_10: '0-10 ft',
    PANEL_DISTANCE_10_20: '10-20 ft',
    PANEL_DISTANCE_20_30: '20-30 ft',
    PANEL_DISTANCE_30: '30+ ft',
    HP_MONOLITH: 'hp_monolith',
    SERVICE_ERROR: 'service_error',
    CENTRAL_AC: 'CentralAc',
    ELECTRIC_OVEN: 'ElectricOven',
    ELECTRIC_DRYER: 'ElectricDryer',
    ELECTRIC_WATER_HEATER: 'ElectricWaterHeater',
    ELECTRIC_INDUCTION_COOKTOP: 'ElectricOrInductionCooktop',
    NONE: 'none',
    PANEL_LOCATION_GARAGE: 'garage',
    PANEL_LOCATION_BASEMENT: 'basement',
    PANEL_LOCATION_EXTERIOR: 'exterior_wall',
    PANEL_LOCATION_OTHER: 'other',
    PROJECT_UNITY_UTILITY: 'project_unity_utility',
    EV_CHARGER_ADAPTER: 'EVChargerAdapter',
    INVERTER: 'Inverter',
    SMART_PANEL: 'SmartPanel',
    ESSENTIALS: 'essentials',
    ESSENTIALS_PLUS: 'essentials_plus',
    EVERYTHING: 'everything',
    THERMOSTAT: 'Thermostat',

    OTHER_UTILITY: 'other_utility',
    SINGLE_FAMILY_HOUSE: 'single_family_house',
    CONDO: 'condo',
    TOWNHOUSE: 'townhouse',
    OTHER: 'other',
    EMERGENCY: 'emergency',
    NON_EMERGENCY: 'non_emergency',
};

export const FINAL_STEPS = [COMPONENT_NAMES.VERIFY_COMPLETE, COMPONENT_NAMES.CSM_HANDOFF];

export const CHOICE_STEP_CHANGES = {
    SHOPPING_FOR: {
        SOLAR_PV: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: true },
            { OWNS_PROPERTY: true },
            { CSM_HANDOFF: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
            { ADDRESS: true },
            { ROOF_PIN: true },
            { NAME: true },
            { EMAIL: true },
            { VERIFY_COMPLETE: true },
        ],
        EV_CHARGER: [
            { EV_CHARGER: true },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
        HEATPUMP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
    },
    EV_CHARGER: {},
    PROPERTY_TYPE: {
        RESIDENTIAL: [
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
        ],
        COMMERCIAL: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
        NONPROFIT: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
    },

    OWNS_PROPERTY: {
        TRUE: [{ RENT_CRITERIA: false }],
        // Rent means a personal non-commercial lease, so the residential rent criteria step is shown
        FALSE: [{ RENT_CRITERIA: true }],
    },
    OWNS_PROPERTY_COMMERCIAL: {},
    TIME_TO_PURCHASE: {},
    RENT_CRITERIA: {
        RENT_OTHER: [
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
        ],
        RENT_HOA: [{ STORAGE_INTEREST: true }, { REMOVE_TREES: true }, { ROOF_AGE: true }, { ELECTRICITY_BILL: true }],
        RENT_SELF: [
            { STORAGE_INTEREST: false },
            { STORAGE_REASON: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
        ],
    },
    CSM_HANDOFF: {},
    ELECTRICITY_BILL: {},
    STORAGE_INTEREST: {
        INTERESTED: [{ STORAGE_REASON: true }],
        NOT_INTERESTED: [{ STORAGE_REASON: false }],
    },
    STORAGE_REASON: {},
    ADDRESS: {},
    ROOF_PIN: {},
    ROOF_AGE: {},
    REMOVE_TREES: {},
    ORGANIZATION_NAME: {},
    NAME: {},
    EMAIL: {},
};

const NUMERIC_CONSTANT_VALUES = {
    CO2_TONS_PER_KWH: 0.0007601,
    CO2_TREES_PER_KWH: 0.0176808,
    AVG_NATIONAL_ELECTRICITY_BILL: 23, // 23 cents/kwh
};

const SERVICE_LEVEL_VALUES = {
    FULL: 'full',
    LIMITED: 'limited',
    NONE: 'none',
};

const handler = {
    get(target, name) {
        if (name in target) {
            return target[name];
        }
        throw new Error(`invalid key: ${name}`);
    },
};

export const COMPONENTS = new Proxy(COMPONENT_NAMES, handler);
export const COMPONENT_COUNT = Object.keys(COMPONENT_NAMES).length;
export const CHOICES = new Proxy(CHOICE_VALUES, handler);
export const NUMERIC_CONSTANT = new Proxy(NUMERIC_CONSTANT_VALUES, handler);
export const SERVICE_LEVEL = new Proxy(SERVICE_LEVEL_VALUES, handler);
export const COMMERCIAL_PROPERTY_TYPES = [CHOICES.COMMERCIAL, CHOICES.NONPROFIT];

export const MARKET_URL = '/market/';
export const ES_SITE_MARKET_START_PATH = '/market/start/';

export const QUOTE_REDIRECT_PATH = '/my-quotes/review/';

export const LOGGED_IN_STEPS = [
    COMPONENTS.VERIFY_COMPLETE,
    COMPONENTS.EMAIL,
    COMPONENTS.SERVICE_ERROR,
    COMPONENTS.GREAT_CHOICE,
    COMPONENTS.PANEL_DISTANCE,
    COMPONENTS.PANEL_LOCATION,
    COMPONENTS.ELECTRICAL_PANEL,
    COMPONENTS.EV_THANKS,
];
export const PROPERTY_OVERRIDE_COOKIE_NAME = 'mfdp_mvp_property_type_override';
const COOKIE_PATH = '/';
const MAX_AGE_IN_SECONDS = 15;
const SAME_SITE_RULE = 'lax';
export const PROPERTY_OVERRIDE_COOKIE_PARAMS = {
    path: COOKIE_PATH,
    maxAge: MAX_AGE_IN_SECONDS,
    sameSite: SAME_SITE_RULE,
};
export const ERROR_REASON = {
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    SUBMISSION: 'SUBMISSION',
    COOKIE_AUTHORIZATION: 'COOKIE_AUTHORIZATION',
    NO_SERVICE: 'NO_SERVICE',
    INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
    FILE_TOO_LARGE: 'FILE_TOO_LARGE',
    UNKNOWN: 'UNKNOWN',
};

export const GOOGLE_MAPS_API_MAX_LOAD_TIME = 1500;

export const GOOGLE_MAPS_API_LIBRARIES = {
    CORE: 'core',
    MAPS: 'maps',
    MARKER: 'marker',
    PLACES: 'places',
};

export const HP_EA_PHONE_NUMBER = '(617) 284-4960';

export const PROJECT_UNITY_UTILITY_NAME = 'SMUD';

export const NEXSTAR_ZIP_CODES = new Set([
    // ABC Plumbing
    '60007',
    '60010',
    '60002',
    '60103',
    '60005',
    '60009',
    '60012',
    '60014',
    '60124',
    '60016',
    '60137',
    '60017',
    '60143',
    '60154',
    '60018',
    '60169',
    '60019',
    '60175',
    '60181',
    '60020',
    '60185',
    '60188',
    '60190',
    '60193',
    '60195',
    '60025',
    '60410',
    '60026',
    '60029',
    '60030',
    '60031',
    '60033',
    '60037',
    '60040',
    '60041',
    '60042',
    '60043',
    '60044',
    '60045',
    '60046',
    '60047',
    '60048',
    '60050',
    '60053',
    '60056',
    '60061',
    '60062',
    '60064',
    '60068',
    '60069',
    '60070',
    '60072',
    '60073',
    '60074',
    '60076',
    '60077',
    '60078',
    '60079',
    '60081',
    '60082',
    '60083',
    '60084',
    '60085',
    '60087',
    '60088',
    '60090',
    '60091',
    '60093',
    '60095',
    '60096',
    '60097',
    '60098',
    '60099',
    '60100',
    '60102',
    '60106',
    '60119',
    '60130',
    '60131',
    '60140',
    '60152',
    '60153',
    '60155',
    '60156',
    '60160',
    '60164',
    '60165',
    '60170',
    '60171',
    '60176',
    '60180',
    '60183',
    '60201',
    '60202',
    '60203',
    '60301',
    '60304',
    '60108',
    '60120',
    '60133',
    '60173',
    '60403',
    '60415',
    '60422',
    '60429',
    '60431',
    '60435',
    '60440',
    '60443',
    '60447',
    '60451',
    '60453',
    '60463',
    '60465',
    '60477',
    '60480',
    '60487',
    '60491',
    '60503',
    '60505',
    '60510',
    '60514',
    '60516',
    '60521',
    '60525',
    '60532',
    '60539',
    '60542',
    '60544',
    '60546',
    '60555',
    '60559',
    '60561',
    '60564',
    '60567',
    '60586',
    '60004',
    '60013',
    '60015',
    '60021',
    '60022',
    '60035',
    '60051',
    '60060',
    '60065',
    '60067',
    '60071',
    '60089',
    '60104',
    '60105',
    '60110',
    '60125',
    '60136',
    '60142',
    '60162',
    '60163',
    '60191',
    '60302',
    '60305',
    '60402',
    '60428',
    '60445',
    '60455',
    '60456',
    '60457',
    '60458',
    '60459',
    '60471',
    '60501',
    '60519',
    '60526',
    '60534',
    '60601',
    '60602',
    '60603',
    '60605',
    '60606',
    '60607',
    '60608',
    '60609',
    '60610',
    '60611',
    '60613',
    '60614',
    '60615',
    '60616',
    '60618',
    '60622',
    '60625',
    '60626',
    '60630',
    '60631',
    '60634',
    '60638',
    '60639',
    '60640',
    '60641',
    '60642',
    '60643',
    '60645',
    '60646',
    '60647',
    '60651',
    '60652',
    '60653',
    '60654',
    '60655',
    '60656',
    '60657',
    '60659',
    '60660',
    '60661',
    '60664',
    '60689',
    '60691',
    '60706',
    '60707',
    '60712',
    '60714',
    '60804',
    '60805',
    // TR Miller
    '60478',
    '60803',
    '60404',
    '60412',
    '60418',
    '60423',
    '60439',
    '60446',
    '60448',
    '60490',
    '60504',
    '60515',
    '60452',
    '60523',
    '60543',
    '60545',
    '60554',
    '60558',
    '60560',
    '60462',
    '60585',
    '60467',
    '60464',
    '60482',
    '60430',
    '60008',
    '60101',
    '60107',
    '60118',
    '60123',
    '60126',
    '60134',
    '60139',
    '60148',
    '60157',
    '60172',
    '60174',
    '60177',
    '60184',
    '60187',
    '60189',
    '60192',
    '60194',
    '60197',
    '60432',
    '60441',
    '60502',
    '60506',
    '60513',
    '60517',
    '60527',
    '60538',
    '60540',
    '60563',
    '60565',
    '60199',
    '60416',
    '60421',
    '60433',
    '60434',
    '60444',
    '60474',
    '60507',
    '60522',
    '60541',
    '60572',
    '60598',
    '60935',
    // Rescue Air
    '75409',
    '75043',
    '76040',
    '75212',
    '75454',
    '75034',
    '75010',
    '76006',
    '75207',
    '75071',
    '76227',
    '75007',
    '75050',
    '75208',
    '75424',
    '75068',
    '75287',
    '75060',
    '75223',
    '75078',
    '75006',
    '75019',
    '75061',
    '75224',
    '75009',
    '76205',
    '75234',
    '75150',
    '75211',
    '75069',
    '76210',
    '75229',
    '75041',
    '75236',
    '75002',
    '75065',
    '75067',
    '75244',
    '75233',
    '75407',
    '76226',
    '76092',
    '75230',
    '75206',
    '75098',
    '75077',
    '76177',
    '75201',
    '75094',
    '75022',
    '76244',
    '75220',
    '75202',
    '75048',
    '76262',
    '76182',
    '75235',
    '75226',
    '75089',
    '75057',
    '76137',
    '75225',
    '75246',
    '75088',
    '75056',
    '76148',
    '75231',
    '75040',
    '75024',
    '76180',
    '75238',
    '75044',
    '75093',
    '76039',
    '75209',
    '75070',
    '75080',
    '76021',
    '75205',
    '75035',
    '75252',
    '76022',
    '75214',
    '75013',
    '75248',
    '76111',
    '75218',
    '75025',
    '75254',
    '76117',
    '75228',
    '75023',
    '75240',
    '76118',
    '75247',
    '75075',
    '75243',
    '76120',
    '75219',
    '75074',
    '75042',
    '76053',
    '75204',
    // My Plumber Plus
    '20861',
    '21215',
    '20817',
    '20814',
    '20816',
    '20841',
    '20833',
    '20866',
    '20818',
    '20815',
    '20871',
    '20740',
    '21044',
    '21045',
    '21046',
    '20872',
    '20855',
    '21075',
    '21042',
    '21043',
    '21702',
    '21703',
    '21701',
    '20759',
    '20882',
    '20879',
    '20877',
    '20896',
    '20874',
    '20876',
    '21740',
    '21754',
    '20895',
    '20886',
    '21773',
    '20878',
    '21774',
    '20832',
    '20837',
    '20854',
    '20850',
    '20852',
    '20853',
    '20902',
    '20906',
    '20901',
    '20905',
    '20904',
    '20910',
    '20903',
    '20912',
    '21740',
    '21741',
    '21742',
    '21749',
    '21403',
    '21401',
    '21409',
    '21012',
    '20705',
    '20710',
    '20720',
    '20716',
    '20715',
    '21114',
    '21032',
    '21037',
    '20743',
    '20765',
    '21054',
    '21061',
    '21060',
    '20769',
    '20770',
    '20776',
    '20783',
    '20782',
    '20785',
    '20781',
    '20794',
    '20706',
    '20707',
    '20708',
    '20723',
    '20724',
    '21108',
    '20721',
    '20712',
    '20784',
    '21113',
    '20737',
    '20851',
    '21144',
    '21146',
    '20607',
    '20613',
    '20616',
    '20748',
    '20623',
    '20747',
    '20744',
    '20637',
    '20640',
    '20711',
    '20745',
    '20746',
    '20772',
    '20774',
    '20603',
    '20601',
    '20602',
    '20695',
    '20735',
    '22310',
    '22309',
    '22304',
    '22314',
    '22306',
    '22308',
    '22301',
    '22311',
    '22312',
    '22305',
    '22302',
    '22307',
    '22303',
    '23024',
    '22443',
    '22025',
    '22026',
    '22060',
    '22407',
    '22408',
    '22405',
    '22406',
    '22401',
    '22403',
    '22485',
    '22315',
    '22508',
    '22079',
    '23093',
    '23117',
    '22125',
    '22546',
    '22551',
    '22553',
    '22153',
    '22152',
    '22150',
    '22554',
    '22556',
    '22172',
    '22193',
    '22192',
    '22191',
    '20106',
    '22003',
    '22207',
    '22204',
    '22205',
    '22206',
    '22201',
    '22213',
    '22202',
    '22203',
    '22209',
    '20119',
    '20124',
    '22712',
    '22714',
    '22715',
    '20136',
    '22433',
    '22701',
    '22718',
    '22030',
    '22033',
    '22032',
    '22031',
    '22151',
    '22039',
    '22043',
    '22041',
    '22042',
    '22046',
    '22044',
    '20155',
    '22942',
    '22726',
    '20110',
    '20112',
    '20109',
    '20111',
    '22728',
    '20181',
    '22960',
    '22734',
    '22735',
    '22542',
    '22737',
    '22740',
    '22742',
    '22567',
    '20187',
    '20186',
    '22015',
    '22625',
    '20148',
    '20147',
    '22611',
    '20135',
    '22620',
    '20120',
    '20121',
    '20151',
    '20152',
    '20143',
    '22027',
    '22630',
    '22066',
    '20158',
    '20169',
    '20171',
    '20170',
    '22640',
    '20176',
    '20175',
    '22642',
    '20180',
    '22835',
    '20115',
    '22101',
    '22102',
    '20117',
    '20118',
    '22645',
    '22646',
    '22842',
    '22124',
    '20129',
    '20165',
    '20132',
    '20191',
    '20194',
    '20190',
    '20141',
    '22655',
    '20164',
    '20166',
    '20198',
    '22182',
    '22180',
    '22181',
    '20197',
    '22663',
    '22602',
    '22603',
    '22601',
    '20105',
]);

export const PROJECT_UNITY_ZIP_CODES = new Set([
    '95826',
    '95610',
    '95864',
    '95624',
    '95827',
    '95628',
    '95829',
    '95811',
    '95818',
    '95822',
    '95814',
    '95608',
    '95758',
    '95621',
    '95670',
    '95825',
    '95834',
    '95693',
    '95842',
    '95831',
    '95832',
    '95843',
    '95841',
    '95833',
    '95828',
    '95823',
    '95747',
    '95630',
    '95673',
    '95816',
    '95660',
    '95824',
    '95815',
    '95626',
    '95662',
    '95817',
    '95838',
    '95830',
    '95632',
    '95757',
    '95638',
    '95661',
    '95821',
    '95615',
    '95820',
    '95683',
    '95690',
    '95819',
    '95837',
    '95639',
    '95835',
    '95561',
    '95655',
    '95678',
    '95742',
    '95652',
    '95227',
    '95762',
    '93510',
    '91301',
    '91376',
    '91801',
    '91802',
    '91803',
    '91804',
    '91896',
    '91899',
    '91001',
    '91003',
    '91006',
    '91007',
    '91066',
    '91077',
    '90701',
    '90702',
    '90704',
    '91702',
    '91706',
    '90202',
    '90201',
    '90706',
    '90707',
    '90209',
    '90210',
    '90211',
    '90212',
    '90213',
    '91501',
    '91502',
    '91503',
    '91504',
    '91505',
    '91506',
    '91507',
    '91508',
    '91510',
    '91521',
    '91522',
    '91523',
    '91526',
    '91302',
    '91372',
    '91303',
    '91304',
    '91305',
    '91309',
    '91351',
    '91386',
    '91387',
    '90745',
    '90746',
    '90747',
    '90749',
    '90895',
    '91310',
    '91384',
    '90703',
    '91311',
    '91313',
    '91714',
    '91715',
    '91716',
    '91711',
    '90220',
    '90221',
    '90222',
    '90223',
    '90224',
    '91722',
    '91723',
    '91724',
    '90230',
    '90231',
    '90232',
    '91765',
    '90239',
    '90240',
    '90241',
    '90242',
    '91008',
    '91009',
    '91010',
    '91731',
    '91732',
    '91734',
    '91735',
    '90245',
    '91316',
    '91416',
    '91426',
    '91436',
    '90247',
    '90248',
    '90249',
    '91201',
    '91202',
    '91203',
    '91204',
    '91205',
    '91206',
    '91207',
    '91208',
    '91209',
    '91210',
    '91221',
    '91222',
    '91225',
    '91226',
    '91740',
    '91741',
    '91344',
    '91394',
    '91745',
    '90710',
    '90716',
    '90250',
    '90251',
    '90254',
    '90255',
    '90301',
    '90302',
    '90303',
    '90304',
    '90305',
    '90306',
    '90307',
    '90308',
    '90309',
    '90310',
    '90311',
    '90312',
    '91011',
    '91012',
    '91214',
    '91224',
    '90637',
    '90638',
    '90639',
    '91744',
    '91746',
    '91747',
    '91749',
    '91750',
    '93532',
    '90711',
    '90712',
    '90713',
    '90714',
    '90715',
    '93534',
    '93535',
    '93536',
    '93539',
    '93584',
    '93586',
    '90260',
    '90261',
    '93543',
    '93544',
    '90717',
    '90801',
    '90802',
    '90803',
    '90804',
    '90805',
    '90806',
    '90807',
    '90808',
    '90809',
    '90810',
    '90813',
    '90814',
    '90815',
    '90822',
    '90831',
    '90832',
    '90833',
    '90840',
    '90842',
    '90844',
    '90846',
    '90847',
    '90848',
    '90853',
    '90001',
    '90002',
    '90003',
    '90004',
    '90005',
    '90006',
    '90007',
    '90008',
    '90009',
    '90010',
    '90011',
    '90012',
    '90013',
    '90014',
    '90015',
    '90016',
    '90017',
    '90018',
    '90019',
    '90020',
    '90021',
    '90022',
    '90023',
    '90024',
    '90025',
    '90026',
    '90027',
    '90028',
    '90029',
    '90030',
    '90031',
    '90032',
    '90033',
    '90034',
    '90035',
    '90036',
    '90037',
    '90038',
    '90039',
    '90040',
    '90041',
    '90042',
    '90043',
    '90044',
    '90045',
    '90046',
    '90047',
    '90048',
    '90049',
    '90050',
    '90051',
    '90052',
    '90053',
    '90054',
    '90055',
    '90056',
    '90057',
    '90058',
    '90059',
    '90060',
    '90061',
    '90062',
    '90063',
    '90064',
    '90065',
    '90066',
    '90067',
    '90068',
    '90070',
    '90071',
    '90072',
    '90073',
    '90074',
    '90075',
    '90076',
    '90077',
    '90078',
    '90079',
    '90080',
    '90081',
    '90082',
    '90083',
    '90084',
    '90086',
    '90087',
    '90088',
    '90089',
    '90091',
    '90093',
    '90095',
    '90096',
    '90099',
    '90134',
    '90189',
    '90262',
    '90263',
    '90264',
    '90265',
    '90266',
    '90267',
    '90292',
    '90295',
    '90270',
    '91345',
    '91346',
    '91395',
    '91016',
    '91017',
    '90640',
    '91754',
    '91755',
    '91756',
    '91020',
    '91021',
    '91023',
    '91321',
    '91322',
    '91343',
    '91393',
    '91601',
    '91602',
    '91603',
    '91605',
    '91606',
    '91609',
    '91611',
    '91612',
    '91615',
    '91616',
    '91618',
    '91324',
    '91325',
    '91327',
    '91328',
    '91329',
    '91330',
    '90650',
    '90651',
    '90652',
    '90272',
    '91331',
    '91333',
    '91334',
    '93550',
    '93551',
    '93552',
    '93590',
    '93591',
    '93599',
    '90274',
    '91402',
    '91412',
    '90723',
    '91101',
    '91102',
    '91103',
    '91104',
    '91105',
    '91106',
    '91107',
    '91109',
    '91110',
    '91114',
    '91115',
    '91116',
    '91117',
    '91121',
    '91123',
    '91124',
    '91125',
    '91126',
    '91129',
    '91182',
    '91184',
    '91185',
    '91188',
    '91189',
    '91199',
    '93553',
    '90660',
    '90661',
    '90662',
    '90293',
    '90296',
    '90094',
    '91766',
    '91767',
    '91768',
    '91769',
    '91326',
    '90275',
    '90277',
    '90278',
    '91335',
    '91337',
    '91770',
    '91771',
    '91772',
    '91748',
    '91773',
    '91340',
    '91341',
    '91775',
    '91776',
    '91778',
    '91108',
    '91118',
    '90731',
    '90732',
    '90733',
    '90734',
    '91350',
    '91380',
    '91382',
    '91383',
    '91390',
    '90670',
    '90671',
    '90401',
    '90402',
    '90403',
    '90404',
    '90405',
    '90406',
    '90407',
    '90408',
    '90409',
    '90410',
    '90411',
    '91403',
    '91413',
    '91423',
    '91495',
    '91024',
    '91025',
    '90755',
    '91733',
    '90280',
    '91030',
    '91031',
    '91381',
    '91604',
    '91614',
    '91352',
    '91353',
    '91040',
    '91041',
    '91342',
    '91392',
    '91356',
    '91357',
    '91780',
    '91610',
    '90290',
    '90501',
    '90502',
    '90503',
    '90504',
    '90505',
    '90506',
    '90507',
    '90508',
    '90509',
    '90510',
    '91042',
    '91043',
    '91608',
    '91354',
    '91355',
    '91385',
    '91607',
    '91617',
    '93563',
    '91401',
    '91404',
    '91405',
    '91406',
    '91407',
    '91408',
    '91409',
    '91410',
    '91411',
    '91470',
    '91482',
    '91496',
    '91499',
    '90291',
    '90294',
    '91046',
    '91788',
    '91789',
    '91790',
    '91791',
    '91792',
    '91793',
    '91307',
    '91308',
    '90069',
    '90601',
    '90602',
    '90603',
    '90604',
    '90605',
    '90606',
    '90607',
    '90608',
    '90609',
    '90610',
    '90744',
    '90748',
    '91306',
    '91396',
    '91364',
    '91365',
    '91367',
    '91371',
    '95002',
    '95008',
    '95009',
    '95011',
    '95013',
    '95014',
    '95015',
    '95020',
    '95021',
    '95026',
    '94022',
    '94023',
    '94024',
    '95030',
    '95031',
    '95032',
    '95033',
    '95035',
    '95036',
    '95037',
    '95038',
    '95140',
    '94035',
    '94039',
    '94040',
    '94041',
    '94042',
    '94043',
    '95042',
    '94301',
    '94302',
    '94303',
    '94304',
    '94306',
    '94309',
    '95044',
    '95101',
    '95103',
    '95106',
    '95108',
    '95109',
    '95110',
    '95111',
    '95112',
    '95113',
    '95115',
    '95116',
    '95117',
    '95118',
    '95119',
    '95120',
    '95121',
    '95122',
    '95123',
    '95124',
    '95125',
    '95126',
    '95127',
    '95128',
    '95129',
    '95130',
    '95131',
    '95132',
    '95133',
    '95134',
    '95135',
    '95136',
    '95138',
    '95139',
    '95141',
    '95148',
    '95150',
    '95151',
    '95152',
    '95153',
    '95154',
    '95155',
    '95156',
    '95157',
    '95158',
    '95159',
    '95160',
    '95161',
    '95164',
    '95170',
    '95172',
    '95173',
    '95190',
    '95191',
    '95192',
    '95193',
    '95194',
    '95196',
    '95046',
    '95050',
    '95051',
    '95052',
    '95053',
    '95054',
    '95055',
    '95056',
    '95070',
    '95071',
    '94305',
    '94085',
    '94086',
    '94087',
    '94088',
    '94089',
    '94151',
    '94137',
    '94139',
    '94140',
    '94141',
    '94142',
    '94143',
    '94144',
    '94145',
    '94146',
    '94147',
    '94158',
    '94159',
    '94160',
    '94161',
    '94163',
    '94164',
    '94172',
    '94177',
    '94188',
    '94120',
    '94103',
    '94104',
    '94105',
    '94107',
    '94108',
    '94109',
    '94110',
    '94111',
    '94112',
    '94114',
    '94115',
    '94116',
    '94117',
    '94118',
    '94119',
    '94102',
    '94121',
    '94122',
    '94123',
    '94124',
    '94125',
    '94126',
    '94127',
    '94129',
    '94130',
    '94131',
    '94132',
    '94133',
    '94134',
]);
